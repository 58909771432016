import { customElement, property } from 'lit/decorators.js';
import { html, LitElement } from 'lit';
import { style } from './hero-teaser.styles';
import './hero-teaser.scss';

@customElement('sbk-hero-teaser')
export class SbkHeroTeaser extends LitElement {
  static shadowRootOptions = {...LitElement.shadowRootOptions, delegatesFocus: true};

  @property()
  href: string = '';

  @property({attribute: 'button-label'})
  buttonLabel: string = '';

  static get styles() {
    return style;
  }

  render() {
    return html`
       <div class="hero-teaser">
       <div class="hero-teaser-headline-wrapper">
        ${this.renderHeadline()}
       </div>
        <div class="hero-teaser-image-container">
          <div class="hero-teaser__image">
            <slot name="image"></slot>
            <div class="image-award-wrapper">
              <slot name="image-award"></slot>
            </div>
          </div>
        </div>
        <div class="hero-teaser__content-wrapper">
          <div class="hero-teaser__body">
            <slot></slot>
          </div>
          ${this.renderLink()}
        </div>
      </div>
    `;
  }

 
  renderHeadline() {
    return html`<slot class="hero-teaser__headline" name="heading"></slot>`;
  }

  renderLink() {
    return this.href && this.buttonLabel ?
        html`<div>
          <sbk-button  variant="cta" href=${this.href}>${this.buttonLabel}</sbk-button>
        </div>`
        : '';
  }
}