import { sbkCoreBreakpointMd, sbkCoreBreakpointLg, sbkCoreBreakpointXl } from "@/tokens/variables";
import { css, unsafeCSS } from "lit";
const breakpointMd = unsafeCSS(`${sbkCoreBreakpointMd}`);
const breakpointLg = unsafeCSS(`${sbkCoreBreakpointLg}`);
const breakpointXl = unsafeCSS(`${sbkCoreBreakpointXl}`);

export const style = css`

    :host{
        --hero-teaser__color: var(--_hero-teaser__color, var(--sbkHeroTeaserColorFgColorOnLightDefault));
        --hero-teaser__bg-color: var(--_hero-teaser__bg-color, var(--sbkHeroTeaserColorFgColorOnDarkDefault));
        --hero-teaser__content-gap: var(--sbkHeroTeaserSpacingSmallScrGap);
        --hero-teaser__stickers-gap: var(--sbkHeroTeaserSpacingSmallScrStickersGap);
        --hero-teaser__headline-font: var(--sbkHeroTeaserTypographySmallScreenHeadline);
        --hero-teaser__sticker-height: var(--sbkHeroTeaserSizingViewportSmStickerHeight);
        --hero-teaser__headline-margin:  var(--sbkHeroTeaserSpacingLargeScrMarginBottomHeadline);

        @media (min-width: ${breakpointMd}) {
            --hero-teaser__sticker-height: var(--sbkHeroTeaserSizingViewportMdStickerHeight);
        }

        @media (min-width: ${breakpointLg}) {
            --hero-teaser__stickers-gap: var(--sbkHeroTeaserSpacingLargeScrStickersGap);
            --hero-teaser__headline-margin: var(--sbkHeroTeaserSpacingLargeScrMarginBottomHeadline);
            --hero-teaser__sticker-height: var(--sbkHeroTeaserSizingViewportLgStickerHeight);
            --hero-teaser__headline-font: var(--sbkHeroTeaserTypographyLargeScreenHeadline);
        }

        @media (min-width: ${breakpointXl}) {
            --hero-teaser__sticker-height: var(--sbkHeroTeaserSizingViewportXlStickerHeight);
        }
    }

    .hero-teaser-headline-desktop {
        display:none;
    }

    .hero-teaser-headline-mobile {
        display: block;
        margin-bottom: var(--sbkHeroTeaserSpacingSmallScrGap);
    }

    .hero-teaser {
        color: var(--hero-teaser__color);
        display: grid;

        @media all and (min-width: ${breakpointMd}) {
            grid-template-columns: calc(100% / 12* 6) calc(100% / 12* 5);
            grid-template-rows: minmax(0px, auto) minmax(0px, 1fr);
            grid-column-gap: calc(100% / 12);
            padding: 0;

            .hero-teaser-headline-wrapper {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
            }
            .hero-teaser__content-wrapper {
                grid-column-start: 1;
                grid-column-end: span 1;
                grid-row-start: 2;
            }
            .hero-teaser-image-container {
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 2;
            }
        }

        @media all and (min-width: ${breakpointXl}) {
            .hero-teaser-headline-wrapper {
                grid-column-start: 1;
                grid-column-end: span 1;
                grid-row-start: 1;
            }
            .hero-teaser__content-wrapper {
                grid-column-start: 1;
                grid-column-end: span 1;
                grid-row-start: 2;
            }
            .hero-teaser-image-container {
                grid-column-start: 2;
                grid-column-end: span 1;
                grid-row-start: 1;
                grid-row-end: span 2;
            }
        }
    }

    .hero-teaser-headline-wrapper {
        @media all and (min-width: ${breakpointXl}) {
            margin-top: var(--sbkHeroTeaserSpacingSmallScrGap);
        }

        .hero-teaser__headline::slotted(*) {
            margin: 0 !important;
            margin-bottom: var(--sbkHeroTeaserSpacingLargeScrMarginBottomHeadline) !important;
            font: var(--hero-teaser__headline-font) !important;
            hyphens: auto;
            word-break: break-word;
        }
    }

    .hero-teaser__body {
        margin-bottom: var(--sbkHeroTeaserSpacingLargeScrMarginBottomList);
    }

    .hero-teaser-image-container {
        position: relative;
        max-width: var(--sbkHeroTeaserSizingViewportSmImageMaxWidth);
        margin-bottom: var(--sbkHeroTeaserSpacingLargeScrMarginBottomList);
        @media all and (min-width: ${breakpointMd}) {
            max-width: unset;
            width: 100%;
            margin-bottom: 0;
        }
    }

    .hero-teaser__image {
        position: relative;

        @media all and (min-width: ${breakpointMd}) {
            width: 84%;
        }

        @media all and (min-width: ${breakpointXl}) {
            width: 100%;
        }
    }

    .image-award-wrapper {
        display: flex;
        gap: var(--hero-teaser__stickers-gap);
        position: absolute;
        left: 0;
        bottom: -10%;
        z-index: 2;
        height: var(--hero-teaser__sticker-height);
        @media all and (min-width: ${breakpointMd}) {
            bottom: 0;
            height: var(--hero-teaser__sticker-height);
        }
        @media all and (min-width: ${breakpointLg}) {
            height: var(--hero-teaser__sticker-height);
        }
        @media all and (min-width: ${breakpointXl}) {
            height: var(--hero-teaser__sticker-height);
        }
    }

    ::slotted([slot="image-award"]) {
        width: auto;
        max-height: var(--sbkHeroTeaserSizingViewportSmStickerHeight);
        display: inline-block;

        @media all and (min-width: ${breakpointMd}) {
            max-height: var(--sbkHeroTeaserSizingViewportMdStickerHeight);
        }

        @media all and (min-width: ${breakpointLg}) {
            max-height: var(--sbkHeroTeaserSizingViewportLgStickerHeight);
        }

        @media all and (min-width: ${breakpointXl}) {
            max-height: var(--sbkHeroTeaserSizingViewportXlStickerHeight);
        }
    }
`;
